import * as FilePond from 'filepond/dist/filepond.min';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

FilePond.registerPlugin(FilePondPluginImageExifOrientation);
FilePond.registerPlugin(FilePondPluginImagePreview);

['turbo:render', 'turbo:load'].forEach(function (evt) {
  document.addEventListener(evt, function (e) {
    const inputElement = document.querySelector("input[type='file'].inline-file-upload");
    const pond = FilePond.create(inputElement);
    console.log(pond)
  })
})
