import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="maps"
export default class extends Controller {
  static values = {
    init: String,
    wrapper: String,
    prefix: String,
    lat: String,
    lon: String,
  }
  static targets = ['map']

  connect() {
    const prefix = this.prefixValue;
    const elem = document.getElementById('map-' + prefix);
    const lat = this.latValue;
    const lon = this.lonValue;
    if (this.initValue === 'delay') {
      document.querySelector(this.wrapperValue).addEventListener('shown.bs.collapse', function () {
        initMap(elem, prefix, lat, lon);
      })
    } else {
      initMap(elem, prefix, lat, lon);
    }
  }
}

let initMap = (elem, prefix, lat, lon) => {
  let map = L.map(elem).setView([lat, lon], 13);

  L.tileLayer('https://maps.rgimg.de/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  setMarker(map, prefix, [lat, lon], false)
}
