import flatpickr from "flatpickr/dist/flatpickr.min"
import {German} from "flatpickr/dist/l10n/de"

['turbo:render', 'turbo:load'].forEach(function (evt) {
    document.addEventListener(evt, function (e) {
        document.querySelectorAll('.date-picker').forEach((pickerElem) => {
            let opts = {locale: German};
            for (let d in pickerElem.dataset) {
                opts[d] = pickerElem.dataset[d];
            }
            let fp = flatpickr(pickerElem, opts);
            fp.calendarContainer.classList.add("calendar-" + fp.element.id)
        })

    })
})

// Special date + time handling for eventideas
let DatePicker = {

    init: (elem) => {
        if (elem === null) return;
        this.picker = flatpickr(elem, {
            locale: German,
            inline: true,
        });
        this.pickedDates = [];

        // Set minutes and hours to "not empty" when either of them is selected
        // or set both to empty if either is selected empty
        this.$hours = document.querySelector('#date-picker-hours');
        this.$minutes = document.querySelector('#date-picker-minutes');
        this.$hours.onchange = (evt) => {
            if (this.$hours.value === '-') {
                this.$minutes.value = '-'
            } else {
                if (this.$minutes.value === '-')
                    this.$minutes.value = '00'
            }
        }
        this.$minutes.onchange = (evt) => {
            if (this.$minutes.value === '-') {
                this.$hours.value = '-'
            } else {
                if (this.$hours.value === '-')
                    this.$hours.value = '00'
            }
        }

        // add a selected date to the list
        document.querySelector('#add-select-date').onclick = (evt) => {
            evt.preventDefault();
            let date = this.picker.selectedDates[0];
            console.log(this.picker)
            if (date !== undefined) {
                let hours = this.$hours.value;
                let minutes = this.$minutes.value;
                DatePicker.addPickedDate(date, hours, minutes);
            }
        }
        return this;
    },

    addPickedDate: (date, hours, minutes) => {
        let hash = '' + date + hours + minutes;
        console.log("hash", hash)
        let time;
        if (parseInt(hours) >= 0 && parseInt(minutes) >= 0) {
            time = hours + ':' + minutes;
        }
        let exists = false;
        this.pickedDates.forEach((d) => {
            if (d.hash === hash) exists = true;
        })
        if (exists) return;
        this.pickedDates.push({date: date, time: time, hours: hours, minutes: minutes, hash: hash})
        this.pickedDates = this.pickedDates.sort((a, b) => {
            return (new Date(a.date).getTime()) - (new Date(b.date).getTime())
        });
        DatePicker.updateDatesList();
    },

    updateDatesList: () => {
        let dates = document.querySelector('#selected-dates-list');
        dates.innerHTML = '';
        this.pickedDates.forEach((d, idx) => {
            let el = document.createElement('div');
            el.className = "btn btn-outline-secondary mb-1";
            el.innerText = d.date.toLocaleString('de-DE', {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
            }) + (d.time ? ' ' + d.time : '');
            // set date in calendar
            el.onclick = (elem) => {
                this.picker.setDate(Date.parse(elem.target.querySelector('input').dataset.date))
                this.$hours.value = elem.target.querySelector('input').dataset.hours;
                this.$minutes.value = elem.target.querySelector('input').dataset.minutes;
            };
            // delete entry
            let del = document.createElement('span');
            del.className = "float-end ps-2 pe-1";
            del.role = "button";
            del.innerHTML = '&times';
            del.onclick = (elem) => {
                elem.stopPropagation()
                let idx = elem.target.parentNode.querySelector('input').dataset.idx;
                this.pickedDates.splice(idx, 1);
                DatePicker.updateDatesList();
            };
            el.appendChild(del);
            let input = document.createElement('input');
            input.name = 'calendardate[]'
            input.type = 'hidden';
            input.value = d.date.toLocaleString('de-DE', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric'
            }) + (d.time ? ' ' + d.time : '');
            input.dataset.date = d.date;
            input.dataset.time = d.time;
            input.dataset.hours = d.hours;
            input.dataset.minutes = d.minutes;
            input.dataset.idx = String(idx);
            el.appendChild(input);
            dates.appendChild(el);
        })
    },

    setErrorState: () => {
        this.picker.calendarContainer.classList.add('border');
        this.picker.calendarContainer.classList.add('border-danger');
    },

}
document.DatePicker = DatePicker;
