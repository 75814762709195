import { DirectUpload } from "@rails/activestorage"

import {Editor} from '@tiptap/core'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import bootstrap from "bootstrap/dist/js/bootstrap"
import * as FilePond from 'filepond/dist/filepond.min';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

FilePond.registerPlugin(FilePondPluginImageExifOrientation);
FilePond.registerPlugin(FilePondPluginImagePreview);

window.setupEditor = (elem) => {
  let $div = document.querySelector(elem);
  let editor = new Editor({
    element: $div,
    editorProps: {
      attributes: {
        class: 'form-control ' + $div.dataset.class,
      },
    },
    extensions: [
      History,
      Link.configure({
        openOnClick: false,
        validate: href => /^https?:\/\//.test(href),
      }),
      StarterKit.configure({
        heading: {
          levels: [2]
        },
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right'],
      }),
      Image.configure({
        inline: true,
      })
    ],
    content: document.querySelector($div.dataset.contentElement).value,
  });
  editorMenu.forEach((menuEntry) => {
    document.querySelector('.editor-button-' + menuEntry.name).addEventListener('click', (e) => {
      e.preventDefault();
      menuEntry.action(editor);
      updateMenu(editor);
    })
  })
  editor.on('update', ({editor}) => {
    document.querySelector(document.querySelector('.editor').dataset.contentElement).value = editor.getHTML();
  })
  editor.on('selectionUpdate', ({editor}) => {
    updateMenu(editor);
  })
  updateMenu(editor);
};

let updateMenu = (editor) => {
  editorMenu.forEach((menuEntry) => {
    if (menuEntry.type === 'toggle') {
      let el = document.querySelector('.editor-button-' + menuEntry.name);
      if (editor.isActive(menuEntry.active !== undefined ? menuEntry.active : menuEntry.name)) {
        el.classList.add('btn-secondary')
        el.classList.remove('btn-outline-secondary')
      } else {
        el.classList.remove('btn-secondary')
        el.classList.add('btn-outline-secondary')
      }
    }
  })
}
let editorMenu = [
  {
    type: 'toggle', name: 'bold', action: (editor) => {
      editor.chain().focus().toggleBold().run()
    }
  },
  {
    type: 'toggle', name: 'italic', action: (editor) => {
      editor.chain().focus().toggleItalic().run()
    }
  },
  {
    type: 'toggle', name: 'heading', action: (editor) => {
      editor.chain().focus().toggleHeading({level: 2}).run()
    }
  },
  {
    type: 'toggle', name: 'paragraph', action: (editor) => {
      editor.chain().focus().setParagraph().run()
    }
  },
  {
    type: 'toggle', name: 'bulletList', action: (editor) => {
      editor.chain().focus().toggleBulletList().run()
    }
  },
  {
    type: 'toggle', name: 'orderedList', action: (editor) => {
      editor.chain().focus().toggleOrderedList().run()
    }
  },
  {
    type: 'toggle', name: 'alignLeft', active: { textAlign: 'left' }, action: (editor) => {
      editor.chain().focus().setTextAlign('left').run()
    }
  },
  {
    type: 'toggle', name: 'alignCenter', active: { textAlign: 'center' }, action: (editor) => {
      editor.chain().focus().setTextAlign('center').run()
    }
  },
  {
    type: 'toggle', name: 'alignRight', active: { textAlign: 'right' }, action: (editor) => {
      editor.chain().focus().setTextAlign('right').run()
    }
  },
  {
    type: 'set', name: 'link', action: (editor) => {
      editLink(editor)
    }
  },
  {
    type: 'set', name: 'unlink', action: (editor) => {
      editor.chain().focus().unsetLink().run()
    }
  },
  {
    type: 'set', name: 'hr', action: (editor) => {
      editor.chain().focus().setHorizontalRule().run()
    }
  },
  {
    type: 'set', name: 'image', action: (editor) => {
      addImage(editor)
    }
  },
  {
    type: 'set', name: 'undo', action: (editor) => {
      editor.chain().focus().undo().run()
    }
  },
  {
    type: 'set', name: 'redo', action: (editor) => {
      editor.chain().focus().redo().run()
    }
  },
];

let editLink = (editor) => {
  const previousUrl = editor.getAttributes('link').href
  const {view, state} = editor
  const {from, to} = view.state.selection

  // if already a link use selected text or node text, otherwise use selected text only
  let text;
  if (previousUrl !== undefined) {
    text = from === to ? editor.state.doc.nodeAt(from).text : state.doc.textBetween(from, to, '')
  } else {
    text = state.doc.textBetween(from, to, '')
  }
  console.log("selected:", text)
  console.log("xxx", from, to, view.state.selection);
  let target = '_blank';
  //const url = window.prompt('URL', previousUrl)
  linkChooser(editor, text, previousUrl, target);
}
let setLink = (editor, text, url, target) => {
  if (url === null) {
    return
  }

  // empty
  if (url === '') {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .run()

    return
  }

  // update link
  editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({href: url, target: target})
    .run()

  // replace text
  const selection = editor.view.state.selection;
  editor.chain().focus().insertContentAt({
    from: selection.from,
    to: selection.to
  }, text).run();
}

let linkChooser = (editor, text, url, target) => {
  let modal = document.getElementById('link-modal');
  let $modal = new bootstrap.Modal(modal, {
    keyboard: false
  })
  document.getElementById('link_text').value = text;
  document.getElementById('link_url').value = url !== undefined ? url : '';
  document.getElementById('link-set').addEventListener('click', function () {
    $modal.hide();
    const text = document.getElementById('link_text').value;
    const url = document.getElementById('link_url').value;
    const target = document.getElementById('link_target').checked ? '_blank' : '';
    console.log("tut ", text, url, target)
    setLink(editor, text, url, target)
  });
  $modal.show();
}

let addImage = (editor) => {
    imageChooser(editor)
}

let imageChooser = (editor) => {
    let modal = document.getElementById('image-modal');
    let $modal = new bootstrap.Modal(modal, {
      keyboard: false
    });
    const inputElement = document.querySelector("#new_image");
    const pond = FilePond.create(inputElement);
    document.getElementById('image-set').addEventListener('click', function () {
      $modal.hide();
      const file = pond.getFile(0).file;
      if (file) {
        uploadFile(file, editor, (signed_id) => {
            console.log( {src: URL.createObjectURL(file), signed_id: signed_id })
            editor.chain().focus().setImage({ src: URL.createObjectURL(file), alt: signed_id }).run()
            pond.destroy();
        });
      }
    });
    $modal.show();
}

const uploadFile = (file, editor, callback) => {
  // your form needs the file_field direct_upload: true, which
  //  provides data-direct-upload-url
  const url = document.querySelector("#direct-upload").dataset.directUploadUrl;
  const model = document.querySelector("#direct-upload").dataset.directUploadModel;
  console.log(url)
  const upload = new DirectUpload(file, url);

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.setAttribute("multiple", "multiple");
      hiddenField.name = model + '[images][]';
      editor.options.element.closest('form').appendChild(hiddenField);
      callback(blob.signed_id);
    }
  })
}
